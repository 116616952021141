import React, { FocusEvent, useState } from 'react';
import { IconButton } from '@contentful/f36-button';
import { Table } from '@contentful/f36-table';
import { TextInput } from '@contentful/f36-forms';
import { DeleteIcon } from '@contentful/f36-icons';
import { PinnedProduct } from '../../types/PinnedProduct';

export interface PinnedProductsRowProps {
  pinnedProduct: PinnedProduct;
  onPinnedProductRemoved: () => void;
  onPinnedProductChanged: (pinnedProduct: PinnedProduct) => boolean;
}

const PinnedProductsRow = (props: PinnedProductsRowProps): JSX.Element => {
  const [invalid, setInvalid] = useState<boolean>(false);

  const handleMpvIdChange = (event: FocusEvent<HTMLInputElement>) => {
    const succeeded: boolean = props.onPinnedProductChanged({
      mpvId: event.target.value,
      position: props.pinnedProduct.position
    });

    setInvalid(!succeeded);
  };

  const handlePositionChange = (event: FocusEvent<HTMLInputElement>) => {
    props.onPinnedProductChanged({
      mpvId: props.pinnedProduct.mpvId,
      position: parseInt(event.target.value)
    });
  };

  return (
    <Table.Row>
      <Table.Cell>
        <TextInput
          data-testid="mpv-id-field"
          isInvalid={invalid}
          defaultValue={props.pinnedProduct.mpvId}
          onBlur={handleMpvIdChange}
        />
      </Table.Cell>
      <Table.Cell>
        <TextInput
          data-testid="position-field"
          type="number"
          defaultValue={props.pinnedProduct.position.toString()}
          onBlur={handlePositionChange}
        />
      </Table.Cell>
      <Table.Cell>
        <IconButton
          data-testid="delete-button"
          variant="transparent"
          icon={<DeleteIcon variant="negative" size="large" />}
          aria-label="Unpin product"
          onClick={props.onPinnedProductRemoved}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default PinnedProductsRow;
