import { buildValidationResult, PinnedProductValidationResult } from '../types/PinnedProductValidationResult';
import Papa from 'papaparse';
import { PinnedProduct } from '../types/PinnedProduct';

export const validatePinnedProductsFile = (
  contents: Papa.ParseResult<PinnedProduct>,
  startPosition: number
): PinnedProductValidationResult => {
  try {
    if (!validateColumns(contents)) {
      return buildValidationResult(false, 'There are some invalid columns in the provided file');
    }
    if (startPosition === 0 && !validateUniquePositions(contents)) {
      return buildValidationResult(false, 'There are products with the same position in the provided file');
    }
    if (!validateUniqueMpvIds(contents)) {
      return buildValidationResult(false, 'There are products with the same MPV ID in the provided file');
    }
    return buildValidationResult(true, '');
  } catch {
    return buildValidationResult(false, 'Unexpected error when validating products file.');
  }
};

const validateColumns = (results: Papa.ParseResult<PinnedProduct>): boolean => {
  if (
    results.meta.fields?.length !== 2 ||
    results.meta.fields[0] !== 'mpvId' ||
    results.meta.fields[1] !== 'position'
  ) {
    return false;
  }
  return true;
};

const validateUniquePositions = (results: Papa.ParseResult<PinnedProduct>): boolean => {
  return new Set(results.data.map((p) => p.position)).size === results.data.length;
};

const validateUniqueMpvIds = (results: Papa.ParseResult<PinnedProduct>): boolean => {
  return new Set(results.data.map((p) => p.mpvId)).size === results.data.length;
};
