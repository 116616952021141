import { PinnedProduct, pinnedProductPositionCompareFn } from '../types/PinnedProduct';

export const shiftPositionsIfNewOneIsTaken = (products: PinnedProduct[], newPosition: number): PinnedProduct[] => {
  const sortedProducts: PinnedProduct[] = products.sort(pinnedProductPositionCompareFn);
  let pos: number = sortedProducts.findIndex((product: PinnedProduct) => product.position === newPosition);

  if (pos >= 0) {
    while (pos < sortedProducts.length) {
      sortedProducts[pos].position++;

      if (pos + 1 === sortedProducts.length || sortedProducts[pos + 1].position !== sortedProducts[pos].position) {
        break;
      }

      pos++;
    }
  }

  return sortedProducts;
};
