import React from 'react';
import PinnedProductsRow from './PinnedProductRow';
import PinnedProductsAddRow from './PinnedProductsAddRow';
import { Table } from '@contentful/f36-table';
import { PinnedProduct, pinnedProductPositionCompareFn } from '../../types/PinnedProduct';
import { Box, Flex } from '@contentful/f36-core';
import { Button } from '@contentful/f36-components';

export interface PinnedProductsProps {
  pinnedProducts: PinnedProduct[];
  onPinnedProductAdded: (pinnedProduct: PinnedProduct) => boolean;
  onAllPinnedProductsRemoved: () => void;
  onPinnedProductRemoved: (index: number) => void;
  onPinnedProductChanged: (index: number, pinnedProduct: PinnedProduct) => boolean;
}

const PinnedProducts = (props: PinnedProductsProps): JSX.Element => {
  console.log('PRODUCTS SENT', props.pinnedProducts);
  return (
    <>
      <Flex justifyContent="end" gap="spacingL" paddingBottom="spacingM" paddingRight="spacingL">
        <Button variant="negative" data-testid="delete-all-mpvIds-button" onClick={props.onAllPinnedProductsRemoved}>
          Remove all MPV IDs
        </Button>
      </Flex>
      <Box paddingRight="spacingL">
        <Table data-testid="products-table">
          <Table.Head>
            <Table.Row>
              <Table.Cell>MPV ID</Table.Cell>
              <Table.Cell width="100">Position</Table.Cell>
              <Table.Cell width="50" />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <>
              {props.pinnedProducts
                ?.sort(pinnedProductPositionCompareFn)
                .map((pinnedProduct: PinnedProduct, index: number) => {
                  return (
                    <PinnedProductsRow
                      key={`${pinnedProduct.mpvId}-${pinnedProduct.position}`}
                      pinnedProduct={pinnedProduct}
                      onPinnedProductChanged={(product) => props.onPinnedProductChanged(index, product)}
                      onPinnedProductRemoved={() => props.onPinnedProductRemoved(index)}
                    />
                  );
                })}
            </>
            <PinnedProductsAddRow onPinnedProductAdded={props.onPinnedProductAdded} />
          </Table.Body>
        </Table>
      </Box>
    </>
  );
};

export default PinnedProducts;
