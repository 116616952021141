export interface PinnedProductValidationResult {
  isValid: boolean;
  errorMessage: string;
}

export const buildValidationResult = (isValid: boolean, errorMessage: string): PinnedProductValidationResult => {
  return {
    isValid: isValid,
    errorMessage: errorMessage
  };
};
