import React, { useEffect, useMemo, useState } from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';

import PinnedProducts from './../components/PinnedProducts/PinnedProducts';
import PinnedProductsSchedule from './../components/PinnedProducts/PinnedProductsSchedule';
import { Accordion } from '@contentful/f36-accordion';
import { Notification } from '@contentful/f36-notification';
import { Checkbox, Note } from '@contentful/f36-components';
import { Flex } from '@contentful/f36-core';
import { PinnedProductsConfiguration } from './../types/PinnedProductsConfiguration';
import { PinnedProduct } from './../types/PinnedProduct';
import { validatePinnedProduct } from './../validations/PinnedProductValidation';
import {
  validatePinnedProductConfiguration,
  validatePinnedProductsMerge
} from './../validations/PinnedProductConfigurationValidation';
import PinnedProductsBulkUpload from './../components/PinnedProducts/PinnedProductsBulkUpload';
import { getDateOnly, getEndOfDayForDate } from './../manipulation/DateManipulation';
import { shiftPositionsIfNewOneIsTaken } from './../manipulation/ArrayManipulation';

const Field = () => {
  const defaultPinnedProductsConfiguration: PinnedProductsConfiguration = useMemo(() => {
    return {
      useCustomPinnedProducts: false,
      products: [],
      schedule: {
        enabled: false
      }
    };
  }, []);

  const sdk = useSDK<FieldExtensionSDK>();

  const [pinnedProductsConfiguration, setPinnedProductsConfiguration] = useState<PinnedProductsConfiguration>(
    defaultPinnedProductsConfiguration
  );
  const [productsExpanded, setProductsExpanded] = useState<boolean>(true);
  const [useCustomConfigurationCheckbox, setCustomConfigurationCheckbox] = useState(
    sdk.field.getValue()?.useCustomPinnedProducts ?? false
  );

  useEffect(() => {
    sdk.window.startAutoResizer();

    let persistedPinnedProducts: PinnedProductsConfiguration = sdk.field.getValue();

    if (!validatePinnedProductConfiguration(persistedPinnedProducts)) {
      console.log('PinnedProducts application found empty or invalid configuration. Recreating it.');
      persistedPinnedProducts = defaultPinnedProductsConfiguration;
      sdk.field.setValue(persistedPinnedProducts);
    }

    setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
      ...prevPinnedProductsConfiguration,
      ...defaultPinnedProductsConfiguration
    }));

    const detachValueChangeHandler = sdk.field.onValueChanged((newConfiguration: PinnedProductsConfiguration) => {
      setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
        ...prevPinnedProductsConfiguration,
        ...newConfiguration
      }));
    });

    return () => {
      if (detachValueChangeHandler) detachValueChangeHandler();
    };
  }, [defaultPinnedProductsConfiguration, sdk.field, sdk.window]);

  useEffect(() => {
    const fieldContentHasChanged = (): boolean => {
      const storedValue = sdk.field.getValue();
      return (
        pinnedProductsConfiguration !== defaultPinnedProductsConfiguration &&
        JSON.stringify(storedValue) !== JSON.stringify(pinnedProductsConfiguration)
      );
    };

    if (fieldContentHasChanged()) {
      sdk.field.setValue(pinnedProductsConfiguration);
    }
  }, [defaultPinnedProductsConfiguration, pinnedProductsConfiguration, sdk.field]);

  useEffect(() => {
    setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
      ...prevPinnedProductsConfiguration,
      useCustomPinnedProducts: useCustomConfigurationCheckbox
    }));
  }, [useCustomConfigurationCheckbox]);

  const removePinnedProduct = (position: number) => {
    setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
      ...prevPinnedProductsConfiguration,
      products: pinnedProductsConfiguration.products.filter((_, _index) => _index !== position)
    }));
  };

  const removeAllPinnedProducts = () => {
    setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
      ...prevPinnedProductsConfiguration,
      products: []
    }));
  };

  const addPinnedProduct = (addedPinnedProduct: PinnedProduct): boolean => {
    const validationResult = validatePinnedProduct(-1, addedPinnedProduct, pinnedProductsConfiguration.products);
    if (!validationResult.isValid) {
      Notification.error(validationResult.errorMessage);
      return false;
    }

    setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
      ...prevPinnedProductsConfiguration,
      products: [
        ...shiftPositionsIfNewOneIsTaken(pinnedProductsConfiguration.products, addedPinnedProduct.position),
        addedPinnedProduct
      ]
    }));

    return true;
  };

  const updatePinnedProduct = (position: number, addedPinnedProduct: PinnedProduct): boolean => {
    const validationResult = validatePinnedProduct(position, addedPinnedProduct, pinnedProductsConfiguration.products);
    if (!validationResult.isValid) {
      Notification.error(validationResult.errorMessage);
      return false;
    }

    setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
      ...prevPinnedProductsConfiguration,
      products: shiftPositionsIfNewOneIsTaken(pinnedProductsConfiguration.products, addedPinnedProduct.position).map(
        (product, index) => (index === position ? { ...addedPinnedProduct } : { ...product })
      )
    }));

    return true;
  };

  const updateScheduleDateRange = (startDate: Date, endDate: Date): void => {
    const schedule = {
      ...pinnedProductsConfiguration.schedule,
      startDate: startDate,
      endDate: endDate
    };
    setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
      ...prevPinnedProductsConfiguration,
      schedule: schedule
    }));
  };

  const enableOrDisableSchedule = (checked: boolean): void => {
    const schedule = {
      ...pinnedProductsConfiguration.schedule,
      enabled: checked,
      startDate: getDateOnly(pinnedProductsConfiguration.schedule.startDate),
      endDate: getEndOfDayForDate(pinnedProductsConfiguration.schedule.endDate)
    };
    setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
      ...prevPinnedProductsConfiguration,
      schedule: schedule
    }));
  };

  const bulkUploadPinnedProducts = (
    products: PinnedProduct[],
    preserveConfiguration: boolean,
    startPosition: number
  ): boolean => {
    let newProducts: PinnedProduct[] = [];
    if (preserveConfiguration) {
      const validationResult = validatePinnedProductsMerge(pinnedProductsConfiguration.products, products);
      if (!validationResult.isValid) {
        Notification.error(validationResult.errorMessage);
        return false;
      }
      newProducts = [...pinnedProductsConfiguration.products];
    }

    if (startPosition !== 0) {
      products.forEach((p: PinnedProduct) => (p.position = startPosition++));
    }

    setPinnedProductsConfiguration((prevPinnedProductsConfiguration) => ({
      ...prevPinnedProductsConfiguration,
      products: [...newProducts, ...products]
    }));

    return true;
  };

  const getNoteMessage = (): string => {
    if (useCustomConfigurationCheckbox) {
      return 'Using CUSTOM pinning for this discovery page';
    }
    return 'Using PARENT pinning configuration';
  };

  return (
    <>
      <Flex flexDirection="column" gap="spacingL" paddingTop="spacingM" paddingBottom="spacingM">
        <Note>{getNoteMessage()}</Note>
        <Checkbox
          name="useCustomPinnedProducts"
          id="useCustomPinnedProducts"
          helpText="If the configuration is empty, there won't be pinned products in this Discovery Page"
          isChecked={useCustomConfigurationCheckbox}
          onChange={() => {
            setCustomConfigurationCheckbox(!useCustomConfigurationCheckbox);
          }}>
          Set custom configuration for this Discovery Page
        </Checkbox>
      </Flex>
      {useCustomConfigurationCheckbox ? (
        <Accordion>
          <Accordion.Item
            title="Products"
            isExpanded={productsExpanded}
            onCollapse={() => setProductsExpanded(!productsExpanded)}
            onExpand={() => setProductsExpanded(!productsExpanded)}>
            <PinnedProducts
              pinnedProducts={pinnedProductsConfiguration.products}
              onPinnedProductAdded={addPinnedProduct}
              onAllPinnedProductsRemoved={removeAllPinnedProducts}
              onPinnedProductRemoved={removePinnedProduct}
              onPinnedProductChanged={updatePinnedProduct}
            />
          </Accordion.Item>
          <Accordion.Item title="Schedule">
            <PinnedProductsSchedule
              schedule={pinnedProductsConfiguration.schedule}
              onDateRangeChanged={updateScheduleDateRange}
              onEnabledChanged={enableOrDisableSchedule}
            />
          </Accordion.Item>
          <Accordion.Item title="Bulk update">
            <PinnedProductsBulkUpload onProductListUploaded={bulkUploadPinnedProducts} />
          </Accordion.Item>
        </Accordion>
      ) : null}
    </>
  );
};

export default Field;
